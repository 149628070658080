body {
    --primary: #636466;
    --background: #ffffff;
    --background-dim: #ffffff;
    --background-dim-light: #e0e0e0;
    --primary-foreground: #ffffff;
    --foreground: #636466;
    --base-unit: 8px;
    --font-family: 'Roboto';
}

html {
    background-color: #f6f3e6;
}